import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';

export function* projectCreate({ payload,requestMethod }) {
    try {       
        yield put({ type: types.PROJECT_LODING });
        let result;
        
          result = yield call(api.projectCreate, payload,requestMethod);
               
        if (result) {
                   
            const projectInfo = result.data; 
            if(projectInfo.dulpicate){
                yield put({ type: types.PROJECT_DUPLICATE, payload: {projectInfo} });
            } 
            else{
                yield put({ type: types.PROJECT_SUCCESS, payload: {projectInfo} });
            }                  
            

        } else {                     
            yield put({ type: types.PROJECT_FAILURE, payload: 'error occur' });
        }
        
    } catch (err) {       
        
    }
}

function* projectSaga(data) {    
    yield takeLatest(types.PROJECT_REQUEST, projectCreate);
    
}

export default projectSaga;
