import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';

export function* viewenvironment({ payload }) {
    try {
       
        yield put({ type: types.VIEWENVIRONMENT_LODING });
        let result;
        result = yield call(api.viewenvironment, payload);
       
        if (result) {
            const environment = result.data          
                yield put({ type: types.VIEWENVIRONMENT_SUCCESS, payload: {environment} });
           
        } else {
            
            const usererror = {
                dseverity: 'error',
                dAlertTitle: 'Error',
                error: result.data.error.message,
                isError: true
            }
    
           // yield put({ type: constants.SET_SERVER_ERROR, payload: usererror });
            yield put({ type: types.VIEWENVIRONMENT_FAILURE, payload: result.data.error });
        }
        
    } catch (err) {
        //yield put({ type: types.LOGIN_FAILURE, payload: err });
       

    }
}

function* viewenvironmentSaga(data) {
    yield takeLatest(types.VIEWENVIRONMENT_REQUEST, viewenvironment);
    
}

export default viewenvironmentSaga;
