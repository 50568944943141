const defaultState = {
    activeStep: 0
};
const wizardReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "NEXT":
            return { ...state, activeStep: action.payload };
        case "BACK":
            return { ...state, activeStep: action.payload };
        default:
            return state;
    }

}

export default wizardReducer;