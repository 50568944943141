const defaultState = {
    selectedAuth:'',
    selectedVal:''
};
const apirecordingReducer = (state = defaultState, action) => {

    switch (action.type) {
        case "selectAuth":            
            return { ...state, selectedAuth:action.payload.selectedAuth,selectedVal:action.payload.selectedVal };
       
        case "clearAuth":
            return {
                ...state,
                selectedAuth:'noauth',
                selectedVal:''
            }
        
        default:
            return state;
    }

}

export default apirecordingReducer;