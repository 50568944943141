import * as types from './types'

const defaultState = {
    thread: {},
    isError: false,
    isSuccess: false,
    isPending: true,
    testcase: {},
    attachment: {},
    allscenarios: []
};



export default function getthreadReducer(state = defaultState, action) {

    switch (action.type) {
        case types.GETTHREADGROUP_LODING:
            return { ...state, isPending: true, isError: false, isSuccess: false }
        case types.GETTHREADGROUP_SUCCESS:
            return { ...state, isPending: false, isError: false, isSuccess: true, thread: action.payload };
        case types.GETTHREADGROUP_FAILURE:
            return { ...state, isPending: false, isError: true, isSuccess: false };
        case types.GETATTACHMENT_LODING:
            return { ...state, isPending: true, isError: false, isSuccess: false }
        case types.GETATTACHMENT_SUCCESS:
            return { ...state, isPending: false, isError: false, isSuccess: true, attachment: action.payload };
        case types.GETATTACHMENT_FAILURE:
            return { ...state, isPending: false, isError: true, isSuccess: false };
        case types.GETALLSCENARIOS_LODING:
            return { ...state, isPending: true, isError: false, isSuccess: false }
        case types.GETALLSCENARIOS_SUCCESS:
            return { ...state, isPending: false, isError: false, isSuccess: true, allscenarios: action.payload };
        case types.GETALLSCENARIOS_FAILURE:
            return { ...state, isPending: false, isError: true, isSuccess: false };
        case types.TESTCASE_LODING:
            return { ...state, isPending: true, isError: false, isSuccess: false }
        case types.TESTCASE_SUCCESS:
            return { ...state, isPending: false, isError: false, isSuccess: true, testcase: action.payload };
        case types.TESTCASE_FAILURE:
            return { ...state, isPending: false, isError: true, isSuccess: false };
        default:
            return state;
    }

}