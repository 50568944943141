import { combineReducers } from 'redux';
import user from '../ducks/gateway/login/reducers';
import signup from '../ducks/gateway/signup/reducers';
import createcode from '../ducks/gateway/forgotpassword/reducers';
import reset from '../ducks/gateway/forgotpassword/resetreducers';

import menu from '../ducks/gateway/menus/reducers';
import wizard from '../ducks/wizard/reducers';
import domaincreate from './domain/createdomain/reducers';
import getdomain from './domain/getdomain/reducers';
import projectcreate from './domain/createproject/reducers';
import getproject from './domain/getproject/reducers';
import getjmx from './testcase/getjmx/reducers';
import getthread from './testcase/gettestcase/reducers'
import createenvironment from './environmentsetup/createenvironment/reducers'
import viewenvironment from './environmentsetup/viewenvironment/reducers'
import deleteenvironment from './environmentsetup/deleteenvironment/reducers'
import viewrun from './environmentsetup/viewrun/reducers'
import fetchmasterurl from './environmentsetup/fetchmasterurl/reducers'
import startenvironment from './environmentsetup/startenvironment/reducers'
import stoprun from './environmentsetup/stoprun/reducers'
import viewreport from './reports/viewreports/reducers'
import  manage from './manage/reducers'
import addscenario from './testcase/addscenairo/reducers'
import subscription from './subscription/reducers'
import defaultreducder from './default/reducer'
import apirecordingReducer from './apirecording/reducers'
const rootReducer = combineReducers({ 
    apirecordingReducer,addscenario,user,menu,wizard,manage,signup,createcode,reset , domaincreate,getdomain,projectcreate,getproject,getjmx,getthread,createenvironment,
    viewenvironment,deleteenvironment,viewrun,viewreport,fetchmasterurl,startenvironment,stoprun,subscription,defaultreducder 
});

export default rootReducer;