import * as types from './types'

const defaultState = {
    jmx:[],
    isError: false,   
    isSuccess: false,
    isPending: true,
  
};



export default function getjmxReducer(state = defaultState, action) {
    
    switch (action.type) {
        case types.GETJMX_LODING:
            return { ...state, isPending: true, isError: false, isSuccess: false}
        case types.GETJMX_SUCCESS:
            return { ...state,isPending: false, isError: false, isSuccess: true,jmx:action.payload};
        case types.GETJMX_FAILURE:
            return { ...state, isPending: false, isError: true, isSuccess: false};
        
        default:
            return state;
    }

}