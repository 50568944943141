import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';

export function* deleteenvironment({ payload }) {
    try {
       
        yield put({ type: types.DELETEENVIRONMENT_LODING });
        let result;
        result = yield call(api.deleteenvironment, payload);
       
        if (result) {
           
                yield put({ type: types.DELETEENVIRONMENT_SUCCESS, payload: 'success'});
          
            
        } else {
            
            yield put({ type: types.DELETEENVIRONMENT_FAILURE, payload: 'erroe' });
        }
        
    } catch (err) {
        //yield put({ type: types.LOGIN_FAILURE, payload: err });
       

    }
}

function* deleteenvironmentSaga(data) {
    yield takeLatest(types.DELETEENVIRONMENT_REQUEST, deleteenvironment);
    
}

export default deleteenvironmentSaga;
