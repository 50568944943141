import * as types from './types'

const defaultState = {
    isError: false,
    isPending: false,
    isSuccess: false,
    isDuplicate: false

};



export default function projectcreateReducer(state = defaultState, action) {

    switch (action.type) {
        case types.PROJECT_LODING:
            return { ...state, isPending: true, isError: false, isSuccess: false, isDuplicate: false }
        case types.PROJECT_SUCCESS:
            return { ...state, isPending: false, isError: false, isSuccess: true, isDuplicate: false };
        case types.PROJECT_FAILURE:
            return {
                ...state, isPending: false, isError: true, isSuccess: false, isDuplicate: false
            };
        case types.PROJECT_DUPLICATE:
            return {
                ...state, isPending: false, isError: false, isSuccess: false, isDuplicate: true
            };
        case types.PROJECT_CLEAR:
            return {
                ...state, isPending: false, isError: false, isSuccess: false, isDuplicate: false
            };

        default:
            return state;
    }

}