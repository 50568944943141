import axios from 'axios'

const recordInstance = axios.create({

})

recordInstance.interceptors.request.use(request=>{
    return request
})

export default recordInstance