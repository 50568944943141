import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';

export function* getThread({ payload }) {
    try {       
       
        yield put({ type: types.GETTHREADGROUP_LODING });
        let result;
        result = yield call(api.getThread, payload);  
          
        if (result.data) {            
            const domainInfo = result.data; 
            yield put({ type: types.GETTHREADGROUP_SUCCESS, payload: domainInfo });
        } else {                    
            yield put({ type: types.GETTHREADGROUP_FAILURE, payload: "GETTHREADGROUP FAILURE" });
        }
        
    } catch (err) {       
       

    }
}

export function* getViewAttachment({ payload }) {
    try {       
       
        yield put({ type: types.GETATTACHMENT_LODING });
        let result;
        result = yield call(api.getViewAttachment, payload);  
   
        if (result.data) {            
            const domainInfo = result.data; 
            yield put({ type: types.GETATTACHMENT_SUCCESS, payload: domainInfo });
        } else {                    
            yield put({ type: types.GETATTACHMENT_FAILURE, payload: "GETTHREADGROUP FAILURE" });
        }
        
    } catch (err) {       
        

    }
}

export function* getAllScenarios({ payload }) {
    try {       
     
        yield put({ type: types.GETALLSCENARIOS_LODING });
        let result;
        result = yield call(api.getAllScenarios, payload);  
       
        if (result.data) {            
            const domainInfo = result.data; 
            yield put({ type: types.GETALLSCENARIOS_SUCCESS, payload: domainInfo });
        } else {                    
            yield put({ type: types.GETALLSCENARIOS_FAILURE, payload: "GETALLSCENARIOS FAILURE" });
        }
        
    } catch (err) {       
        

    }
}

export function* getTestcaseById({ payload }) {
    try {       
       
        yield put({ type: types.TESTCASE_LODING });
        let result;
        result = yield call(api.getTestcaseById, payload);  
         
        if (result.data) {            
            const testcaseInfo = result.data; 
            yield put({ type: types.TESTCASE_SUCCESS, payload: testcaseInfo });
        } else {                    
            yield put({ type: types.TESTCASE_FAILURE, payload: "TESTCASE FAILURE" });
        }
        
    } catch (err) {       
        

    }
}

function* getthreadSaga(data) {
    yield takeLatest(types.GETTHREADGROUP_REQUEST, getThread);
    yield takeLatest(types.TESTCASE_REQUEST, getTestcaseById);
    yield takeLatest(types.GETATTACHMENT_REQUEST, getViewAttachment);
    yield takeLatest(types.GETALLSCENARIOS_REQUEST, getAllScenarios);
    
}

export default getthreadSaga;
