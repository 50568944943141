import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';

export function* createenvironment({ payload }) {
    try {
       
        yield put({ type: types.CREATEENVIRONMENT_LODING });
        let result;
        result = yield call(api.createenvironment, payload);
        if (result) {        
                yield put({ type: types.CREATEENVIRONMENT_SUCCESS, payload: 'success' });
        } else {
            yield put({ type: types.CREATEENVIRONMENT_FAILURE, payload: 'error' });
        }
        
    } catch (err) {
        //yield put({ type: types.LOGIN_FAILURE, payload: err });
        

    }
}

function* createenvironmentSaga(data) {
    yield takeLatest(types.CREATEENVIRONMENT_REQUEST, createenvironment);
    
}

export default createenvironmentSaga;
