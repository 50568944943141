import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';

export function* getProject({ payload }) {
    try {       
        yield put({ type: types.GETPROJECT_LODING });
        let result;
        if(!payload.isNav)
           result = yield call(api.getProject, payload);  
        if(payload.isNav)
            result = yield call(api.getProjectbyuserId, payload);
          
        if (result.data) {            
            const projectInfo = result.data; 
            yield put({ type: types.GETPROJECT_SUCCESS, payload: projectInfo });
        } else {                    
            yield put({ type: types.GETPROJECT_FAILURE, payload: "GET PROJECT FAILURE" });
        }
        
    } catch (err) {       
        
    }
}

function* getProjectSaga(data) {
    yield takeLatest(types.GETPROJECT_REQUEST, getProject);
    
}

export default getProjectSaga;
