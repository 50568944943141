import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';

export function* viewrun({ payload }) {
    try {
       
        yield put({ type: types.VIEWRUN_LODING });
        let result;
        if(payload.flag){
            result = yield call(api.viewrun, payload); 
        }else{
            result = yield call(api.viewallrun, payload); 
        }
              
        if (result.data) {    
                  
            const runInfo = result.data           
            yield put({ type: types.VIEWRUN_SUCCESS, payload: {runInfo} });
           
        } else {
           
            yield put({ type: types.VIEWRUN_FAILURE, payload: 'error' });
        }
        
    } catch (err) {
        
       

    }
}

function* viewrunSaga(data) {
    yield takeLatest(types.VIEWRUN_REQUEST, viewrun);
    
}

export default viewrunSaga;
