import * as types from './types'

const defaultState = {
    addscenario: [],
    isError: false,
    isSuccess: false,
    isPending: true,

};



export default function addscenarioReducer(state = defaultState, action) {

    switch (action.type) {
        case types.ADDSCENARIO_LODING:
            return { ...state, isPending: true, isError: false, isSuccess: false }
        case types.ADDSCENARIO_SUCCESS:
            return { ...state, isPending: false, isError: false, isSuccess: true, addscenario: action.payload };
        case types.ADDSCENARIO_FAILURE:
            return { ...state, isPending: false, isError: true, isSuccess: false };
        case types.CLEARSCENARIO:
            return {
                addscenario: [],
                isError: false,
                isSuccess: false,
                isPending: false,
            }
        default:
            return state;
    }

}