import * as types from './types'

const defaultState = {
    userInfo:{},
    isCodesend:false,
    isPending: false, isError: false,
    isReset:false, 
    error:{}
};



export default function createcodeReducer(state = defaultState, action) {

    switch (action.type) {
        case types.CODE_LODING:
            return { ...state, isCodesend: false, isPending: true, isError: false }
        case types.CODE_SUCCESS:
            return {
                ...state,
                 userInfo: action.payload.userInfo
                , isCodesend: true, isPending: false, isError: false
            };
    
        case types.CODE_FAILURE:
            return {
                ...state,      
                error: action.payload,         
                isCodesend: false,
                isPending: false,
                isError: true,
               
              
            };

            case types.CODE_RESET:
                return {
                    ...state,               
                    isCodesend: false,
                    isPending: false,
                    isError: false,
                    userInfo:{},
                  
                };

        
        default:
            return state;
    }

}