import axios from 'axios'

const fetchInstance = axios.create({

})

fetchInstance.interceptors.request.use(request=>{
    
    return request
})

export default fetchInstance