import * as types from './types'

const defaultState = {
    environment: [],    
    error:[],    
    isError: false,
    isPending:false,
    isSuccess:false
    
};



export default function viewenvironmentReducer(state = defaultState, action) {
 
    switch (action.type) {
        case types.VIEWENVIRONMENT_LODING:
            return {...state,isPending: true, isError: false,isSuccess:false}
        case types.VIEWENVIRONMENT_SUCCESS:
            return {...state,
                environment: action.payload.environment
                ,isPending: false, isError: false,isSuccess:true };
  
        case types.VIEWENVIRONMENT_FAILURE:
            return {...state, 
                    error:[action.payload],                    
                    isPending: false, 
                    isError: true, 
                    isSuccess:false
                   };
    
        default:
            return state;
    }

}