import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';

export function* startenvironment({ payload }) {
    try {
       
        yield put({ type: types.STARTENVIRONMENT_LODING });
        let result;
        result = yield call(api.startenvironment, payload);
       
        if (result) {        
            yield put({ type: types.STARTENVIRONMENT_SUCCESS, payload: result.data });         
        } else {
            
            yield put({ type: types.STARTENVIRONMENT_FAILURE, payload: 'error' });
        }
        
    } catch (err) {
        //yield put({ type: types.LOGIN_FAILURE, payload: err });
        

    }
}

function* startenvironmentSaga(data) {
    yield takeLatest(types.STARTENVIRONMENT_REQUEST, startenvironment);
    
}

export default startenvironmentSaga;
