import * as types from './types'

const defaultState = {
    envSuccess :{},
    isError: false,
    isPending:false,
    isDuplicate:false,
    isSuccess:false
    
};



export default function startenvironmentReducer(state = defaultState, action) {
 
    switch (action.type) {
        case types.STARTENVIRONMENT_LODING:
            return {...state,isPending: true, isError: false,isDuplicate:false}
        case types.STARTENVIRONMENT_SUCCESS:
            return {...state,
                isSuccess: true,
                isPending: false, isError: false,isDuplicate:false,envSuccess:action.payload };
        case types.STARTENVIRONMENT_DUPLICATE:
            return {...state,                   
               isPending: false, isError: false,isDuplicate:true };
        case types.STARTENVIRONMENT_FAILURE:
            return {...state,                                    
                    isPending: false, 
                    isError: true, 
                    isDuplicate:false
                   };
        case types.CLEARENVIRONMENT_SUCCESS:
            return {...state,envSuccess :{},
            isError: false,
            isPending:false,
            isDuplicate:false,
            isSuccess:false}
    
        default:
            return state;
    }

}