import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';
// import { push } from 'react-router-redux';
import configureStore from '../../../store/configureStore';

function* login({ payload }) {
    try {
        yield put({ type: types.LOGIN_LODING });
        let result = yield call(api.login, payload);         
        if(result.status == 200) {    
           
                const userInfo = {                
                    isLoggedIn: true,
                    firstname: result.data.firstName,
                    lastname:result.data.lastName,
                    email:result.data.userEmail,
                    username:result.data.userName,
                    userId:result.data.userId,
                    allInfo:result.data
                };
               
                localStorage.setItem('USER_INFO', JSON.stringify(userInfo));            
                yield put({ type: types.LOGIN_SUCCESS, payload: {userInfo} });
         

        } else if(result.response.status == 500){        
            localStorage.clear();
            yield put({ type: types.LOGIN_FAILURE, payload: result.response.data.message }); 

        }else {
          
            const usererror = {
                dseverity: 'error',
                dAlertTitle: 'Error',
                error: result.data.error.message,
                isError: true
            }
            localStorage.clear();
           // yield put({ type: constants.SET_SERVER_ERROR, payload: usererror });
            yield put({ type: types.LOGIN_FAILURE, payload: result.data.error });
        }
        
    } catch (err) {
        //yield put({ type: types.LOGIN_FAILURE, payload: err });
        
        localStorage.clear();
        yield put({ type: types.LOGIN_FAILURE, payload: "Incorrect email or password" });
    }
}

function* logoutUser({ payload }) {
    try {  
        
        localStorage.removeItem('USER_INFO'); 
        const store = configureStore();
        store.persistor.purge();
        yield put({ type: types.LOGOUT_SUCCESS, payload });
        return { type: types.LOGOUT_SUCCESS };
    } catch (err) {
        yield put({ type: types.LOGOUT_FAILURE, payload: "error" });
    }
}

function* loginReset({ payload }) {
  
    try {  
        yield put({ type: types.LOGIN_RESET_SUCCESS, payload });
    } catch (err) {
        yield put({ type: types.LOGIN_RESET_FAILURE, payload: "error" });
    }
}

function* loginOP({ payload }) {
    try {
        
        yield put({ type: types.LOGIN_LODING });
        let result = yield call(api.loginOP, payload);         
        if(result.status == 200) {    
           
                const userInfo = {                
                    isLoggedIn: true,
                    firstname: result.data.firstName,
                    lastname:result.data.lastName,
                    email:result.data.userEmail,
                    username:result.data.userName,
                    userId:result.data.userId,
                    allInfo:result.data
                };
               
                localStorage.setItem('USER_INFO', JSON.stringify(userInfo));            
                yield put({ type: types.LOGIN_SUCCESS, payload: {userInfo} });
         

        } else if(result.response.status == 500){        
            localStorage.clear();
            yield put({ type: types.LOGIN_FAILURE, payload: result.response.data.message }); 

        }else {
          
            const usererror = {
                dseverity: 'error',
                dAlertTitle: 'Error',
                error: result.data.error.message,
                isError: true
            }
            localStorage.clear();
           // yield put({ type: constants.SET_SERVER_ERROR, payload: usererror });
            yield put({ type: types.LOGIN_FAILURE, payload: result.data.error });
        }
        
    } catch (err) {
        //yield put({ type: types.LOGIN_FAILURE, payload: err });
        
        localStorage.clear();
        yield put({ type: types.LOGIN_FAILURE, payload: "Server error" });
    }
}

function* loginSaga(data) {    
    yield takeLatest(types.LOGIN_REQUEST, login);
    yield takeLatest(types.LOGOUT_REQUEST, logoutUser);
    yield takeLatest(types.LOGIN_OP_REQUEST, loginOP);
    yield takeLatest(types.LOGIN_RESET_REQUEST, loginReset);
}

export default loginSaga;
