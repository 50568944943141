const defaultState = {
    userId: '',
    userName: '',
    domainId: '',
    domainName: '',
    projectName: '',
    projectId: '',
    testcaseName: '',
    apmtype: '',
    testcaseId: '',
    exeName: '',
    slaveCount: '',
    noOfSlave: '',
    scenariaName: '',
    region: '',
    core: '',
    memory: '',
    clusterName: '',
    duration: '',
    currrentDateTime: '',
    testcaseType: false,
    healthCheckstate:false
};
const manageReducer = (state = defaultState, action) => {

    switch (action.type) {
        case "Loggedinstate":
            return { ...state, userId: action.payload.userId, userName: action.payload.userName };
        case "Domainstate":
            return { ...state, domainId: action.payload.domainId, domainName: action.payload.domainName };
        case "projectstate":
            return { ...state, projectId: action.payload.projectId, projectName: action.payload.projectName };
        case "Testcasestate":
            return { ...state, testcaseId: action.payload.testcaseId, testcaseName: action.payload.testcaseName, testcaseType: action.payload.testcaseType, };
        case "Ampstate":
            return { ...state, apmtype: action.payload.apmtype };
        case "healthCheckstate":
            return { ...state, healthCheckstate: action.payload.healthCheckstate };
        case "Executionstate":
            return { ...state, exeName: action.payload.exeName, slaveCount: action.payload.slaveCount };
        case "Loadstate":
            return {
                ...state,
                noOfSlave: action.payload.noOfSlave,
                scenariaName: action.payload.scenariaName,
                region: action.payload.region,
                core: action.payload.core,
                memory: action.payload.memory,
                clusterName: action.payload.clusterName,
                duration: action.payload.duration,
                currrentDateTime: action.payload.currrentDateTime

            }
        case "clearState":
            return {
                ...state, userId: '',
                userName: '',
                domainId: '',
                domainName: '',
                projectName: '',
                projectId: '',
                testcaseName: '',
                apmtype: '',
                testcaseId: '',
                exeName: '',
                slaveCount: '',
                noOfSlave: '',
                scenariaName: '',
                region: '',
                cpu: '',
                memory: '',
                clusterName: '',
                duration: '',
                currrentDateTime: ''
            };
        default:
            return state;
    }

}

export default manageReducer;