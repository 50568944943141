import * as types from './types'

const defaultState = {
    runInfo: null,
    isError: false,
    isPending: false,
    isSuccess: false

};



export default function viewrunReducer(state = defaultState, action) {

    switch (action.type) {
        case types.VIEWRUN_REQUEST:
            return { ...state, isPending: true, isError: false, isSuccess: false, runInfo: null }
        case types.VIEWRUN_LODING:
            return { ...state, isPending: true, isError: false, isSuccess: false }
        case types.VIEWRUN_SUCCESS:
            return { ...state, runInfo: action.payload.runInfo, isPending: false, isError: false, isSuccess: true };
        case types.VIEWRUN_FAILURE:
            return { ...state, error: [action.payload], isPending: false, isError: true, isSuccess: false, runInfo: [] };
        default:
            return state;
    }

}