import axios from './recordInstance';

export function recordApi(url, payload) {

    return axios({
      method: payload.method,
      url: url,
      data: payload.body ? payload.body : null,
      params: payload.params ? payload.params : null,
      headers:payload.headers ? payload.headers : null
  
    }).then((data) => (data))
      .catch((Error) => {
        return { ...Error }
      }
    );
  }