import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';

export function* stoprun({ payload }) {
    try {
       
        yield put({ type: types.STOPRUN_LODING });
        let result;
        result = yield call(api.stoprun, payload);
        
        if (result) {        
            yield put({ type: types.STOPRUN_SUCCESS, payload: result.data });         
        } else {
            
            yield put({ type: types.STOPRUN_FAILURE, payload: 'error' });
        }
        
    } catch (err) {
        //yield put({ type: types.LOGIN_FAILURE, payload: err });
        

    }
}

function* stoprunSaga(data) {
    yield takeLatest(types.STOPRUN_REQUEST, stoprun);
    
}

export default stoprunSaga;
