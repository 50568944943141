const defaultState = {
    data: {}
};
const defaultReducer = (state = defaultState, action) => {
  
    switch (action.type) {
        case "ADD":            
            return { ...state, 
                data:action.payload};
       
        case "REMOVE":
            return {...state,
            data:{}
        };
        default:
            return state;
    }

}

export default defaultReducer;