const defaultState = {    
    isOpen: false   
};
const menuReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "OPEN":
            return { ...state, isOpen: true }; 
        case "CLOSE":
            return { ...state, isOpen: false };
        default:
            return state;
    }

}

export default menuReducer;