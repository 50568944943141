import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';

export function* addScenario({ payload }) {
    try {       
       
        yield put({ type: types.ADDSCENARIO_LODING });
        let result;
        result = yield call(api.addScenario, payload);  
        if (result.data) {            
            const domainInfo = result.data; 
            yield put({ type: types.ADDSCENARIO_SUCCESS, payload: domainInfo.message });
        } else {                    
            yield put({ type: types.ADDSCENARIO_FAILURE, payload: "ADD scenario FAILURE" });
        }
        
    } catch (err) {       
        

    }
}

function* addScenarioSaga(data) {
    yield takeLatest(types.ADDSCENARIO_REQUEST, addScenario);
    
}

export default addScenarioSaga;
