import * as types from './types'

const defaultState = {
   
    isError: false,
    isPending:false,
    isDuplicate:false,
    isSuccess:false
    
};



export default function createenvironmentReducer(state = defaultState, action) {
 
    switch (action.type) {
        case types.CREATEENVIRONMENT_LODING:
            return {...state,isPending: true, isError: false,isDuplicate:false}
        case types.CREATEENVIRONMENT_SUCCESS:
            return {...state,
                isSuccess: true,
                isPending: false, isError: false,isDuplicate:false };
        case types.CREATEENVIRONMENT_DUPLICATE:
            return {...state,                   
               isPending: false, isError: false,isDuplicate:true };
        case types.CREATEENVIRONMENT_FAILURE:
            return {...state,                                    
                    isPending: false, 
                    isError: true, 
                    isDuplicate:false
                   };
    
        default:
            return state;
    }

}