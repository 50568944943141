const defaultState = {
    productId: '',    
    subscriptionId: '',
    subscriptionPlanId: '', 
    subscriptionModeId: '',
    
};
const subscriptionReducer = (state = defaultState, action) => {

    switch (action.type) {
        case "addSubscription":            
            return { ...state, 
                productId: action.payload.productId,                  
                subscriptionId: action.payload.subscriptionId,
                subscriptionPlanId: action.payload.subscriptionPlanId, 
                subscriptionModeId: action.payload.subscriptionModeId};
       
        case "clearSubscription":
            return {...state,
            productId: '', 
            UserId: '',
            companyId: '', 
            subscriptionId: '',
            subscriptionPlanId: '', 
            subscriptionModeId: '',
        };
        default:
            return state;
    }

}

export default subscriptionReducer;