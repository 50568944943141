import * as types from './types'

const defaultState = {
    reportDetails:[],
    isError: false,
    isPending:false,
    isSuccess:false
    
};



export default function viewReportReducer(state = defaultState, action) {

    switch (action.type) {
        case types.VIEWREPORT_LODING:
            return {...state,isPending: true, isError: false,isSuccess:false}
        case types.VIEWREPORT_SUCCESS:
          
            return {...state,
                reportDetails: action.payload.reportDetails
                ,isPending: false, isError: false,isSuccess:true };
      
        case types.VIEWREPORT_FAILURE:
            return {...state, 
                    error:[action.payload],                    
                    isPending: false, 
                    isError: true, 
                    isSuccess:false
                   };
    
        default:
            return state;
    }

}