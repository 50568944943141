import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';
import { push } from 'react-router-redux';


export function* resetpassword({ payload }) {
    try {
       
        yield put({ type: types.FORGOT_LODING });
        let result;
        result = yield call(api.resetpassword, payload.userName.toLowerCase(), payload.password, payload.confirmCode);  
           
        if(result.status == 200) {  
            if(result.data.status){
               
                const userInfo = {                
                    isChange: result.data.status,
                    message:result.data.msg
                  
                };      
                yield put({ type: types.FORGOT_SUCCESS, payload: {userInfo} });
            } else{

               

                const userInfo = {                
                    isChange: result.data.status,
                    message:result.data.msg
                  
                };      
                yield put({ type: types.FORGOT_FAILURE, payload: {userInfo} });

            }      
            
        } else {
           
            yield put({ type: types.FORGOT_FAILURE, payload: result.data.error });
        }
        
    } catch (err) {
      

    }
}

function* resetpasswordSaga(data) {    
    yield takeLatest(types.FORGOT_REQUEST, resetpassword);    
}


export default resetpasswordSaga;
