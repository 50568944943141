import * as types from './types'

const defaultState = {
    isError: false,
    isPending:false,
    issuccess:false    
};
export default function deleteenvironmentReducer(state = defaultState, action) { 
    switch (action.type) {
        case types.DELETEENVIRONMENT_LODING:
            return {...state,isPending: true, isError: false,issuccess:false}
        case types.DELETEENVIRONMENT_SUCCESS:
            return {...state,                
                isPending: false, isError: false,issuccess:true };        
        case types.DELETEENVIRONMENT_FAILURE:
            return {...state,                                        
                    isPending: false, 
                    isError: true, 
                    issuccess:false
                   };    
        default:
            return state;
    }

}