import * as types from './types'

const defaultState = {
    userInfo: '',
    isCodesend: false,
    isPending: false,
    isError: false,
    isReset: false
};



export default function resetReducer(state = defaultState, action) {
    switch (action.type) {
        case types.FORGOT_SUCCESS:
            return {
                ...state,
                userInfo: action.payload.userInfo
                , isCodesend: false, isPending: false, isError: false, isReset: true
            };

        case types.FORGOT_FAILURE:
            return {
                ...state,
                  userInfo: action.payload.userInfo
                , isCodesend: false, isPending: false, isError: true, isReset: false
            };

            case types.FORGOT_RESET:
                return {
                    ...state,               
                    isCodesend: false,
                    isPending: false,
                    isError: false,
                    isReset: false
                };

        default:
            return state;
    }

}