import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';

export function* getJmx({ payload }) {
    try {       
       
        yield put({ type: types.GETJMX_LODING });
        let result;
        result = yield call(api.getJmx, payload);  
          
        if (result.data) {            
            const domainInfo = result.data; 
            yield put({ type: types.GETJMX_SUCCESS, payload: domainInfo });
        } else {                    
            yield put({ type: types.GETJMX_FAILURE, payload: "GET JMX FAILURE" });
        }
        
    } catch (err) {       
        

    }
}

function* getjmxSaga(data) {
    yield takeLatest(types.GETJMX_REQUEST, getJmx);
    
}

export default getjmxSaga;
