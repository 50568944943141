import * as types from './types'

const defaultState = {
    projectDetails: null,
    isError: false,
    isSuccess: false,
    isPending: true,

};



export default function getprojectReducer(state = defaultState, action) {

    switch (action.type) {
        case types.GETPROJECT_REQUEST:
            return { ...state, isPending: true, isError: false, isSuccess: false, projectDetails: null }
        case types.GETPROJECT_LODING:
            return { ...state, isPending: true, isError: false, isSuccess: false }
        case types.GETPROJECT_SUCCESS:
            return { ...state, isPending: false, isError: false, isSuccess: true, projectDetails: action.payload };
        case types.GETPROJECT_FAILURE:
            return { ...state, isPending: false, isError: true, isSuccess: false, projectDetails: [] };

        default:
            return state;
    }

}