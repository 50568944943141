export const GETTHREADGROUP_REQUEST = 'GETTHREADGROUP_REQUEST';
export const GETTHREADGROUP_SUCCESS = 'GETTHREADGROUP_SUCCESS';
export const GETTHREADGROUP_FAILURE = 'GETTHREADGROUP_FAILURE';
export const GETTHREADGROUP_LODING = 'GETTHREADGROUP_LODING';

export const GETATTACHMENT_REQUEST = 'GETATTACHMENT_REQUEST';
export const GETATTACHMENT_SUCCESS = 'GETATTACHMENT_SUCCESS';
export const GETATTACHMENT_FAILURE = 'GETATTACHMENT_FAILURE';
export const GETATTACHMENT_LODING = 'GETATTACHMENT_LODING';

export const GETALLSCENARIOS_REQUEST = 'GETALLSCENARIOS_REQUEST';
export const GETALLSCENARIOS_SUCCESS = 'GETALLSCENARIOS_SUCCESS';
export const GETALLSCENARIOS_FAILURE = 'GETALLSCENARIOS_FAILURE';
export const GETALLSCENARIOS_LODING = 'GETALLSCENARIOS_LODING';

export const TESTCASE_REQUEST = 'TESTCASE_REQUEST';
export const TESTCASE_SUCCESS = 'TESTCASE_SUCCESS';
export const TESTCASE_FAILURE = 'TESTCASE_FAILURE';
export const TESTCASE_LODING = 'TESTCASE_LODING';
