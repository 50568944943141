export const CODE_REQUEST = 'CODE_REQUEST';
export const CODE_SUCCESS = 'CODE_SUCCESS';
export const CODE_FAILURE = 'CODE_FAILURE';
export const CODE_LODING = 'CODE_LODING';
export const CODE_RESET = 'CODE_RESET';

export const FORGOT_REQUEST = 'FORGOT_REQUEST';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_FAILURE = 'FORGOT_FAILURE';
export const FORGOT_LODING = 'FORGOT_LODING';
export const FORGOT_RESET = 'FORGOT_RESET';

