import { call, put, takeLatest, takeEvery, select,takeLeading, cancel } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';
export function* domainCreate({ payload,requestMethod }) {   
    try {     
         
        yield put({ type: types.DOMAIN_LODING });
        let result;
        result = yield call(api.domainCreate, payload,requestMethod);  
        
        if (result) {
            const domainInfo = result.data; 
            if(domainInfo.dulpicate){
                yield put({ type: types.DOMAIN_DUPLICATE, payload: {domainInfo} });
            } 
            else{
                yield put({ type: types.DOMAIN_SUCCESS, payload: {domainInfo} });
            }                  
            

        } else {
                      
            yield put({ type: types.DOMAIN_FAILURE, payload: 'error occur' });
        }
        
    } catch (err) {       
        

    }
}

function* domainSaga(data) {
   
    yield takeLatest(types.DOMAIN_REQUEST, domainCreate);  
    
}

export default domainSaga;
