import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';

export function* getDomain({ payload }) {
    try {       
        yield put({ type: types.GETDOMAIN_LODING });
        let result;
        result = yield call(api.getDomain, payload);  
       
        if (result.data) {            
            const domainInfo = result.data; 
            yield put({ type: types.GETDOMAIN_SUCCESS, payload: domainInfo });
        } else {                    
            yield put({ type: types.GETDOMAIN_FAILURE, payload: "GET DOMAIN FAILURE" });
        }
        
    } catch (err) {       
        
    }
}

function* getdomainSaga(data) {
    yield takeLatest(types.GETDOMAIN_REQUEST, getDomain);
    
}

export default getdomainSaga;
